import { HttpClient } from '@angular/common/http';
import { Injectable ,EventEmitter} from '@angular/core';
//import { AngularFirestore } from '@angular/fire/firestore';
//import { AngularFirestore } from 'angularfire2/firestore';
//import { AngularFireDatabase } from '@angular/fire/database';
//import { AngularFirestore, AngularFirestoreDocument , AngularFirestoreCollection } from 'angularfire2/firestore'
//import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFirestore } from 'angularfire2/firestore';
import { map } from 'rxjs/operators';

/* 
  Generated class for the DataProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class DataProvider {

  constructor(private af:AngularFirestore,public http: HttpClient) {
    //console.log('Hello DataProvider Provider');
    
  }
  printHTML:any;
  printFlag= true;
  searchlimit = 21;
  YadLevnimData:any={

    Password:"",
    UserName:"",
    cityCode:"",
    logo:"",
    name:""

  }

  CasualtysOfTheWeek = [];

  loadmore(){
    this.searchlimit = this.searchlimit + 21;
  }
  loopOnCasualtys(x){
    this.CasualtysOfTheWeek = [];
    const todayDate = new Date();
    const monthDay = todayDate.getDate();
    const monthDy = todayDate.getMonth() + 1;
    const currentYear = todayDate.getFullYear();
  //  console.log('monthDay',monthDay);
  //  console.log('monthDy',monthDy);
    x.forEach(element => {

      this.checkCasualtyWeek(element,monthDy,currentYear);

    });
  }
CasulatyOfTheMonth = [];
  checkCasualtyWeek(Casualty,currentMonth,currentYear){
    if(Casualty.justdate){
   const splitFallDate = Casualty.justdate.split(".");
   if(currentMonth == parseInt(splitFallDate[1])){
    this.CasulatyOfTheMonth.push(Casualty);
    const newDate = new Date(splitFallDate[1]+'/'+splitFallDate[0]+'/'+currentYear);
  //  console.log('newDate',newDate);
    if(this.isThisWeek(newDate)){
      this.CasualtysOfTheWeek.push(Casualty);
    //  console.log('this.CasualtysOfTheWeek',this.CasualtysOfTheWeek);
    }

   }
  }
  // console.log(splitFallDate);
  // console.log('CasulatyOfTheMonth',this.CasulatyOfTheMonth);
  }
  

  isThisWeek (date) {
    const now = new Date();
  
    const weekDay = (now.getDay() + 6) % 7; // Make sure Sunday is 6, not 0
    const monthDay = now.getDate();
    const mondayThisWeek = monthDay - weekDay;
  
    const startOfThisWeek = new Date(+now);
    startOfThisWeek.setDate(mondayThisWeek);
    startOfThisWeek.setHours(0, 0, 0, 0);
  
    const startOfNextWeek = new Date(+startOfThisWeek);
    startOfNextWeek.setDate(mondayThisWeek + 7);
  
    return date >= startOfThisWeek && date < startOfNextWeek;
  }

  newList = [];
  onSearchFLAG = false;
  CasualtyFLAG = false;
  IzkorFLAG = false;
  closeIzkor(){
    this.IzkorFLAG = false;
  }
  enterIzkor(){
    this.IzkorFLAG = true;
  }


  closeCasualty(){
    this.CasualtyFLAG = false;
  }
  enterCasualty(Casualty){
    this.currentCasualty = Casualty;
    this.CasualtyFLAG = true;
  //  console.log('this._DataProvider.currentCasualty',this.currentCasualty);
  }
  inputSearchT:any;
  inFocus(){
   // console.log('in focus');
    this.searchAfter('');
    this.onSearchFLAG = true;
    this.closeCasualty();
  }
  closeSearch(){
    this.onSearchFLAG = false;
    this.inputSearchT = '';
  }
  outFocus(){
  //  console.log('out focus');
  }
  onKeyUp(ev){
    this.searchAfter(ev.target.value);
  }

searchAfter(ser){
  if(ser == ''){
    this.CasualtyDataForSearch=this.CasualtyData;
    return;
  }
  this.CasualtyDataForSearch=[];
  this.newList = this.CasualtyData;
  this.newList.forEach(element => {
    //console.log("-----",JSON.stringify(element),"------");
    var checkName=element.fName + " " +element.lName;
    var breavePlace=element.breavePlace;
    if(checkName.includes(ser)){

      this.CasualtyDataForSearch.push(element);

    }else if(breavePlace.includes(ser)){

      this.CasualtyDataForSearch.push(element);

    }else{
      element.andarta.forEach(anda => {
       // console.log(anda);
        if(breavePlace.includes(anda.name)){
          this.CasualtyDataForSearch.push(element);
        }
      });
     // this.CasualtyDataForSearch.push(element);
      
    }




    
    
  });

  // const found = newList.find(element => element > 10);
}



  currentCasualty:any = {
    fName:"",
    lName: "",
    level: "",
    Gender:'',
    andarta: [],
    unitLogo: {uImg:''}
  };
  CasualtyDataForSearch:any;

  //ServerURL = "http://localhost:3000";
  ServerURL = "http://lm.experteam-game.co.il:3000";

lang='' ;


backGroundCove="";
navigationC = {
  home:true,
  numType: false,
  more:false,
}
spotsList;

showPad = false;

  selectedExebition;
  menuShow = false;
  ExhibitionsService;
  NowLang;
  mapShow = false;
  direction = "ltr";
  spotType='';
  selectedSpot = {
    "id":"",
  };

  Muzeum = {
    _id:"",
    id:"",
    lang:[{"code":"loading"}],
    urlCode:"",
    logo:"assets/imgs/logoDEF.png",
    ExhibitionStyle:"",
    settings: { fontClass: '', type:''},
    borderRadius: '',
    border: '',
    boxShadow:'',
    exTextStyle:'',
    background:'',
  }
  // Muzeum =
  //   {
  //     "_id": "5b93bae8c8445104910ae283",
  //     "defaultLang": "he",
  //     "urlCode":"ComicsHolon",
  //     "ExhibitionStyle":"option2",
  //     "lang": [
  //       {
  //         "code": "en",
  //         "direction": "ltr",
  //         "name": "comics holon",
  //         "discription": "bla bla bla bla"
  //       }
  //     ],
  //     "logo": "src/ihiu/oijoi.png",
  //     "username": "holonComics",
  //     "password": "isajdoiaj",
  //     "link": "http://muses.com/holonvlalal",
  //     "advPop": {},
  //     "settings": {}
  //   };

selectedSpotExe;

setLocalStorage(name,val){
  localStorage.setItem(name, val);
  return true;
}

getLocalStorage(name){
  return localStorage.getItem(name);
}



    // http://localhost:3000/api/ExhibitionByLang/5b93bae8c8445104910ae283/he
    getExhibitionByLang(id,lang){
 //     console.log('getExhibitionByLang');
 //     console.log(this.ServerURL + "/api/ExhibitionByLang/" + id + "/"+ lang)
      return this.http.get(this.ServerURL + "/api/ExhibitionByLang/" + id + "/"+ lang);
    }
    

    // http://localhost:3000/api/spotID/5b96684e174f465f8f200b68
    getSpotByID(id){
  //    console.log('getSpotByID',id);
      return this.http.get(this.ServerURL + "/api/spotID/" + id);
    }

    
    // http://localhost:3000/api/Exhibition/5b93bc0fc8445104910ae284
    getExhibitionByID(DOMid){
    //  console.log('getExhibitionByID',DOMid);
      return this.http.get(this.ServerURL + "/api/Exhibition/" + DOMid);
    }

    // http://localhost:3000/api/spot/5b93bc0fc8445104910ae284/222/en
    getSpotByExebitionCodeAndLang(id,code,lang){
   //   console.log('getSpotByExebitionCodeAndLang',id,code,lang);
      return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/"+ lang);
    }

   // http://localhost:3000/api/Muzeum/ComicsHolon
    getMuzeumByurlCode(urlCode){
  //    console.log('getMuzeumByurlCode');
      return this.http.get(this.ServerURL + "/api/Muzeum/" + urlCode);
    }




    getSpotByExebitionCodeAndLangFB(id,code,lang){
   //   console.log('getSpotByExebitionCodeAndLang',id,code,lang);

      return  this.af.collection('spots',ref=>{
        return ref.where("muzeum","==",id).where("code","==",code).where("lang","==",lang)
       }).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data:any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.id = id;
            data._id = id;

        //    console.log('getExhibitionByLangFB-firebase',data);
         //   console.log('getExhibitionByLangFB-firebase',id);
            return { id, ...data };
          });
        }));


   //   return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/"+ lang);
    }



 choosenEx:any;
 ExhibitionFBcol = this.af.collection('Exhibition');
 FBcol = this.af;





    getExhibitionByIDFB(DOMid){
      //console.log('getExhibitionByIDFB',DOMid);
       this.af.collection('Exhibition').doc(DOMid).ref.get().then(function(doc) {
        if (doc.exists) {
        //    console.log("Document data:", doc.data());
            this.choosenEx = doc.data();
        } else {
         //   console.log("No such document!");
            return {status:false};

        }
      }).catch(function(error) {
        //  console.log("Error getting document:", error);
      });
    
    //  return  this.af.collection('Exhibition').doc(DOMid).valueChanges();

    }

    CasualtyData:any;

    loadCasualtyData(city){
      return  this.af.collection('Casualty',ref => ref.where('cityCode', '==', city)).valueChanges();
    }


    loadCityData(city){
      return  this.af.collection('Institutes',ref => ref.where('cityCode', '==', city)).valueChanges();
    }

    // get the current lang
    getMuzeumData(lang){
    //  console.log('getMuzeumData',lang); 
     // console.log('this.Muzeum',this.Muzeum);
      let  langFrom
      langFrom = this.Muzeum.lang.filter(element => element.code == lang);
    //  console.log('langFrom',langFrom);

      this.direction = langFrom[0].direction;
    
      let returnItem =  langFrom[0];
     // console.log(this.Muzeum.boxShadow);
      returnItem["ExhibitionStyle"]=this.Muzeum.ExhibitionStyle; 
      returnItem["borderRadius"]=this.Muzeum.borderRadius;
      returnItem["border"]=this.Muzeum.border;
      returnItem["boxShadow"]=this.Muzeum.boxShadow;
      returnItem["exTextStyle"]=this.Muzeum.exTextStyle;
      returnItem["background"]=this.Muzeum.background;
      return returnItem;
    }

}
