import { DataProvider } from './providers/data';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from 'angularfire2';


import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { AngularFirestoreModule } from 'angularfire2/firestore';
import { CasualtyComponent } from "./casualty/casualty.component";

const firebaseConfig = {
  apiKey: "AIzaSyAwo_7Voa8vHkE9Hf6hpnke492RtCTGYpg",
  authDomain: "yadlevnim.firebaseapp.com",
  databaseURL: "https://yadlevnim.firebaseio.com",
  projectId: "yadlevnim",
  storageBucket: "yadlevnim.appspot.com",
  messagingSenderId: "938162592997"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot(),
    AngularFirestoreModule,
    AngularFireModule.initializeApp(firebaseConfig),

    AppRoutingModule],
  providers: [
    StatusBar,
    DataProvider,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
